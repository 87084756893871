<template>
    <div class="surface-card shadow-2 border-round p-3">
        <div class="h-full grid">
            <div class="col-12 lg:col-6">
                <!-- Header -->
                <div class="flex flex-row justify-content-between">
                    <span class="text-xl font-bold">TOP 5 MOST LEAKED ACTIVE ASSETS</span>
                    <i class="pi pi-info-circle text-xl text-200 hover:text-900 lg:mr-4"
                       v-tooltip.left="'These are the top 5 most leaked active assets. This number is updated every 24 hours.'"
                    />
                </div>
                <div class="pt-3"/>

                <!-- Content -->
                <div v-if="this.topActiveLeaked === null">
                    <Skeleton class="w-full" height="4rem" borderRadius="16px"></Skeleton>
                </div>
                <div v-else class="grid lg:mr-3 lg:border-right-1 border-300 h-full">
                    <div v-for="(asset, index) in topActiveLeaked" :key="index" class="col-12 grid">
                        <router-link :to="'/asset/' + asset.assetid" v-ripple style="text-decoration: none; color: inherit"
                                     class="w-full flex align-items-center cursor-pointer px-3 py-0 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                            <div class="flex justify-content-start align-items-center">
                                <span class="text-2xl font-bold">{{ index + 1 }}.</span>
                                <div class="pl-3">
                                    <img :src="asset.poster" class="border-round" style="aspect-ratio: 0.675; max-height: 100px; "/>
                                </div>
                                <div class="pl-3">
                                    <span class="flex text-xl font-bold">{{ asset.assetname }}</span>
                                    <div class="flex text-4xl" style="letter-spacing: 0.2em;">
                                        <animated-number :number="asset.count"/>
                                    </div>

                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-6 pt-4 lg:pt-1 lg:mt-1">
                <!-- Header -->
                <div class="flex flex-row justify-content-between">
                    <span class="text-xl font-bold">TOP 5 MOST LEAKED ASSETS</span>
                    <i class="pi pi-info-circle text-xl text-200 hover:text-900"
                       v-tooltip.left="'These are the top 5 most leaked assets of all time. This number is updated every 24 hours.'"
                    />
                </div>
                <div class="pt-3"/>

                <!-- Content -->
                <div v-if="this.topLeaked === null">
                    <Skeleton class="w-full" height="4rem" borderRadius="16px"></Skeleton>
                </div>
                <div v-else class="grid h-full">
                    <div v-for="(asset, index) in topLeaked" :key="index" class="col-12 grid">
                        <router-link :to="'/asset/' + asset.assetid" v-ripple style="text-decoration: none; color: inherit"
                                     class="w-full flex align-items-center cursor-pointer px-3 py-0 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                            <div class="flex justify-content-start align-items-center">
                                <span class="text-2xl font-bold">{{ index + 1 }}.</span>
                                <div class="pl-3">
                                    <img :src="asset.poster" class="border-round" style="aspect-ratio: 0.675; max-height: 100px; "/>
                                </div>
                                <div class="pl-3">
                                    <span class="flex text-xl font-bold">{{ asset.assetname }}</span>
                                    <div class="flex text-4xl" style="letter-spacing: 0.2em;">
                                        <animated-number :number="asset.count"/>
                                    </div>

                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="col-12 p-0 pb-1"/>
        </div>
    </div>
</template>

<script>
import AnimatedNumber from "@/components/elements/AnimatedNumber";
export default {
    name: "TopLeakedAssets",

    components: {
        AnimatedNumber,
    },

    data() {
        return {
            topLeaked: null,
            topActiveLeaked: null,
        }
    },

    mounted() {
        this.getActivity();
    },

    methods: {
        getActivity() {
            this.$factory.analytics.getTopLeaked()
                .then((response) => {
                    console.debug('TopLeakedAssets', response);
                    this.topLeaked = response.alltime;
                    this.topActiveLeaked = response.current;
                })
        },
    },
}
</script>

<style scoped>

</style>
