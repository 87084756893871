<template>
    <div class="surface-card shadow-2 border-round p-3">
        <div class="h-full grid">
            <div class="col-12 lg:col-6">
                <!-- Header -->
                <div class="flex flex-row justify-content-between">
                    <span class="text-xl font-bold">TORRENT PEERS</span>
                    <i class="pi pi-info-circle text-xl text-200 hover:text-900"
                       v-tooltip.left="'This is the number of peers that have been observed torrenting an asset per day over the last 30 days. The values here are approximations due to the complexity of the torrents. This graph is updated every 24 hours.'"
                    />
                </div>
                <div class="pt-3"/>

                <!-- Content -->
                <div v-if="this.torrentData === null">
                    <Skeleton class="w-full" height="4rem" borderRadius="16px"></Skeleton>
                </div>
                <div v-else class="grid">
                    <Chart class="w-full h-20rem" type="line" :data="torrentData" :options="dataGraphOptions"/>
                </div>
            </div>

            <div class="col-12 lg:col-6 pt-4 lg:pt-1 lg:mt-1">
                <!-- Header -->
                <div class="flex flex-row justify-content-between">
                    <span class="text-xl font-bold">AMOUNT OF DATA TORRENTED</span>
                    <i class="pi pi-info-circle text-xl text-200 hover:text-900"
                       v-tooltip.left="'This is the amount of data that has been torrented over the past 30 days represented in terabytes. A terabyte is 1,000,000,000,000 bytes. The values here are approximations due to the complexity of the torrents. This graph is updated every 24 hours.'"
                    />
                </div>
                <div class="pt-3"/>

                <!-- Content -->
                <div v-if="this.torrentBytes === null">
                    <Skeleton class="w-full" height="4rem" borderRadius="16px"></Skeleton>
                </div>
                <div v-else class="grid">
                    <Chart class="w-full h-20rem" type="line" :data="torrentBytes" :options="bytesGraphOptions"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TorrentInfo",

    data() {
        return {
            torrentData: null,
            dataGraphOptions: {adaptive: true, maintainAspectRatio: false, plugins: {legend: {display: false}, }, scales: {x: {ticks: {maxRotation: 0, }}, y: {beginAtZero: true}}, elements: {point: {pointRadius: 0, }, line: {tension: 0.4, borderWidth: 2, }, }, layout: {padding: {top: 3, bottom: 3}}},
            torrentBytes: null,
            bytesGraphOptions: {adaptive: true, maintainAspectRatio: false, plugins: {legend: {display: false}, }, scales: {x: {ticks: {maxRotation: 0, }}, y: {beginAtZero: true, ticks: {callback: function(value) {return value + 'TB'}}}}, elements: {point: {pointRadius: 0, }, line: {tension: 0.4, borderWidth: 2, }, }, layout: {padding: {top: 3, bottom: 3}}},
        }
    },

    mounted() {
        this.getActivity();
    },

    methods: {
        getActivity() {
            this.$factory.analytics.getTorrentInfo()
                .then((response) => {
                    console.debug('TorrentInfo', response);
                    this.torrentData = response.peers
                    this.torrentBytes = response.bytes
                })
        },
    },
}
</script>

<style scoped>

</style>
