<template>
    <div class="surface-card shadow-2 border-round p-3">
        <!-- Header -->
        <div class="flex flex-row justify-content-between">
            <span class="text-xl font-bold">TORRENT ACTIVITY OVER THE PAST WEEK</span>
            <div>
                <i class="pi pi-undo text-xl text-200 hover:text-900 mr-4" @click="changeGraph()"
                   v-tooltip.left="'Toggle between simple and detailed mode'"
                />
                <i class="pi pi-info-circle text-xl text-200 hover:text-900"
                   v-tooltip.left="'This globe shows the number of peers for each country that have downloaded a torrent of one of your assets in the last 7 days. This number is updated every 24 hours.'"
                />
            </div>

        </div>
        <div class="pt-3"/>

        <!-- Content -->
        <Skeleton v-if="this.torrentData === null || (this.worldReady === false && this.simpleMode === false)" class="w-full" height="4rem" borderRadius="16px"></Skeleton>
        <div v-else id="globeWrapper" class="w-full" style="height: 600px">
            <div v-if="simpleMode">
                <DataTable :value="torrentData" :loading="torrentDataLoading" class="h-full w-full" striped-rows
                           paginator :rows="10">
                    <Column field="country" header="Country" ></Column>
                    <Column field="peers" header="Peers" ></Column>
                </DataTable>
            </div>
            <div id="globeDiv"/>
        </div>
    </div>
</template>

<script>
import Globe from "globe.gl";
import GeoJSONData from "@/assets/data/countries.json"

export default {
    name: "TorrentGlobe",

    data() {
        return {
            world: null,
            worldReady: false,
            geoJson: GeoJSONData,
            torrentData: null,
            torrentDataLoading: true,
            colours: null,
            maxPeers: null,

            simpleMode: true
        }
    },

    mounted() {
        this.getData();

        let isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
        console.debug("Is mobile: " + isMobile)
    },

    methods: {
        getData() {
            this.$factory.analytics.getTorrentGlobeData()
                .then((response) => {
                    console.debug('TorrentGlobe', response)
                    this.torrentData = response.data;
                    this.colours = response.colours;

                    // Sort this.torrentData by peers
                    this.torrentData.sort((a, b) => b.peers - a.peers);

                    this.parseData();
                    // this.createGlobe();

                    this.torrentDataLoading = false;
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        parseData() {
            // For each country in geoJson, find the corresponding country in torrentData and add the torrent count
            this.geoJson.features.forEach((country) => {
                let torrentCountry = this.torrentData.find((torrentCountry) => torrentCountry.countrycode === country.properties.ISO_A2);

                if (torrentCountry === undefined) {
                    country.properties.PEERS = 0
                    return;
                }

                country.properties.PEERS = torrentCountry.peers;
            })
            this.maxPeers = Math.max(...this.geoJson.features.map((country) => country.properties.PEERS));

            // Add country names to torrentData
            this.torrentData.forEach((torrentCountry) => {
                let country = this.geoJson.features.find((country) => country.properties.ISO_A2 === torrentCountry.countrycode);
                if (country === undefined) {
                    torrentCountry.country = "Unknown - " + torrentCountry.countrycode;
                    return;
                }
                torrentCountry.country = country.properties.ADMIN;
            })
        },

        createGlobe() {
            let globeDiv = document.getElementById("globeDiv");
            let globeWrapper = document.getElementById("globeWrapper");

            let width = globeWrapper.offsetWidth;
            let height = globeWrapper.offsetHeight;

            // TODO: Change #1f2d40 if the overall theme is changed
            this.world = Globe()(globeDiv)
                .globeImageUrl("https://unpkg.com/three-globe/example/img/earth-night.jpg")
                .backgroundColor(this.$store.state.theme === 'dark' ? '#1f2d40' : '#FFFFFF')
                .showAtmosphere(false)
                .atmosphereAltitude(0.1)
                .pointOfView({ lat: 40.4637, lng: 3.7492, altitude: 1.75 }, 1)
                .width(width)
                .height(height)
                .polygonCapColor((poly) => this.polygonColour(poly))
                .polygonSideColor((poly) => this.polygonSideColour(poly))
                .polygonLabel(({ properties: d }) => `
                  <b>${d.ADMIN} (${d.ISO_A2})</b> <br />
                  Torrent Peers: <i>${d.PEERS.toLocaleString()}</i>
                `);

            this.world.controls().autoRotate = true; // Enable auto rotation
            this.world.controls().autoRotateSpeed = 1; // Set the rotation speed (adjust as needed)

            // Globe data
            this.world.polygonsData(this.geoJson.features.filter((country) => country.properties.ISO_A2 !== 'AQ'))
            this.geoJson.features.forEach((country) => {
                if (country.properties.ISO_A2 === 'AQ') return; // Antarctica

                setTimeout(() => this.world
                        .polygonsTransitionDuration(4000)
                        .polygonAltitude((feat) => feat.properties.PEERS === 0 ? 0 : Math.max(0.01, feat.properties.PEERS / this.maxPeers / 10))
                    , 3000);
            })

            window.addEventListener('resize', () => {
                this.world.width([globeWrapper.offsetWidth])
                this.world.height([globeWrapper.offsetHeight])
            });

            this.worldReady = true;
            globeWrapper.style.visibility = 'visible';

            this.world.width([globeWrapper.offsetWidth])
            this.world.height([globeWrapper.offsetHeight])
        },

        polygonColour(poly) {
            let torrentEntry = this.torrentData.find((torrentCountry) => torrentCountry.countrycode === poly.properties.ISO_A2);
            if (torrentEntry === undefined || torrentEntry.peers === 0) return 'rgba(0, 0, 0, 0)'
            // Pick a colour based on the number of peers within all colours
            let colourIndex = Math.floor(torrentEntry.peers / (this.maxPeers / 5));
            return this.colours[colourIndex];
        },

        polygonSideColour(poly) {
            let torrentEntry = this.torrentData.find((torrentCountry) => torrentCountry.countrycode === poly.properties.ISO_A2);
            if (torrentEntry === undefined || torrentEntry.peers === 0) return 'rgba(0, 0, 0, 0)'
            // Pick a colour based on the number of peers within all colours
            let colourIndex = Math.floor(torrentEntry.peers / (this.maxPeers / 5));
            let rgb = this.hex2rgb(this.colours[colourIndex]);
            return 'rgba(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ', 0.75)';
        },

        hex2rgb(hex) {
            return ['0x' + hex[1] + hex[2] | 0, '0x' + hex[3] + hex[4] | 0, '0x' + hex[5] + hex[6] | 0];
        },

        changeGraph() {
            this.simpleMode = !this.simpleMode;
            let globeDiv = document.getElementById("globeDiv");

            if (this.simpleMode) {
                globeDiv.style.visibility = 'hidden';
            } else {
                if (this.world === null) {
                    this.createGlobe();
                } else {
                    globeDiv.style.visibility = 'visible';
                }
            }
        }
    },

    watch: {
        '$store.state.theme': {
            handler() {
                // TODO: Change #1f2d40 if the overall theme is changed
                if (this.world === null) return;
                this.world.backgroundColor(this.$store.state.theme === 'dark' ? '#1f2d40' : '#FFFFFF');
            },
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
