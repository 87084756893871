<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- First row -->
            <div class="col-12 grid">
                <div class="col-6">
                    <TotalPagesSeenNumberOnly/>
                </div>
                <div class="col-6">
                    <TotalPagesReportedNumberOnly/>
                </div>
                <div class="col-12">
                    <Past60DaysActivity/>
                </div>

                <!-- Contained together so they can be side-by-side on desktop -->
                <div class="col-12">
                    <div class="grid">
                        <div class="col-12 xl:col-5">
                            <TorrentGlobe class="h-full"/>
                        </div>
                        <div class="col-12 xl:col-7" >
                            <TopLeakedAssets class="w-full h-full"/>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <TorrentInfo/>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import TotalPagesSeenNumberOnly from '@/components/graphs/analytics/TotalPagesSeenNumberOnly.vue';
import TotalPagesReportedNumberOnly from "@/components/graphs/analytics/TotalPagesReportedNumberOnly";
import Past60DaysActivity from "@/components/graphs/analytics/Past60DaysActivity";
import TorrentGlobe from "@/components/graphs/analytics/TorrentGlobe";
import TopLeakedAssets from "@/components/graphs/analytics/TopLeakedAssets";
import TorrentInfo from "@/components/graphs/analytics/TorrentInfo";

export default {
    name: "Analytics",
    components: {
        TotalPagesSeenNumberOnly,
        TotalPagesReportedNumberOnly,
        Past60DaysActivity,
        TorrentGlobe,
        TopLeakedAssets,
        TorrentInfo,
    },
}
</script>

<style scoped>

</style>
