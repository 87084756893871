<template>
    <div class="surface-card shadow-2 border-round p-3">
        <!-- Header -->
        <div class="flex flex-row justify-content-between">
            <span class="text-xl font-bold">TOTAL PAGES PROCESSED</span>
            <i class="pi pi-info-circle text-xl text-200 hover:text-900"
               v-tooltip.left="'This is the number of webpages we have processed to date. This number is updated every 24 hours.'"
            />
        </div>
        <div class="pt-3"/>

        <!-- Content -->
        <div v-if="this.totalPages === null">
            <Skeleton class="w-full" height="4rem" borderRadius="16px"></Skeleton>
        </div>
        <div v-else class="grid">
            <!-- Large Screen -->
            <div class="hidden lg:block w-full text-center text-6xl" style="letter-spacing: 0.3em;">
                <animated-number :number="totalPages"/>
            </div>
            <!-- Small Screen -->
            <div class="block lg:hidden w-full text-center text-4xl">
                <animated-number :number="totalPages"/>
            </div>
        </div>
    </div>
</template>

<script>
import AnimatedNumber from "@/components/elements/AnimatedNumber";
export default {
    name: "TotalPagesSeenNumberOnly",

    components: {
        AnimatedNumber,
    },

    data() {
        return {
            totalPages: null,
        }
    },

    mounted() {
        this.getActivity();
    },

    methods: {
        getActivity() {
            this.$factory.analytics.getTotalPagesSeen()
                .then((response) => {
                    console.debug('TotalPagesSeenNumberOnly', response);
                    this.totalPages = response.numeric;
                })
        },
    },
}
</script>

<style scoped>

</style>
