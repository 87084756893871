<template>
    <div class="surface-card shadow-2 border-round p-3">
        <!-- Header -->
        <div class="flex flex-row justify-content-between">
            <span class="text-xl font-bold">LAST 60 DAYS</span>
            <i class="pi pi-info-circle text-xl text-200 hover:text-900"
               v-tooltip.left="'This line chart shows the number of webpages, for each type, we have processed over the last 60 days. This number is updated every 24 hours.'"
            />
        </div>
        <div class="pt-3"/>

        <!-- Content -->
        <div v-if="this.graphInfo === null">
            <Skeleton class="w-full" height="4rem" borderRadius="16px"></Skeleton>
        </div>
        <div v-else class="grid">
            <Chart class="w-full h-20rem" type="line" :data="graphInfo" :options="graphOptions"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "Past60DaysActivity",

    data() {
        return {
            graphInfo: null,
            graphOptions: {adaptive: true, maintainAspectRatio: false, plugins: {legend: {labels: {boxHeight: 2, boxWidth: 30, }}, }, scales: {x: {ticks: {maxRotation: 0, }}, y: {beginAtZero: true, }}, elements: {point: {pointRadius: 0, }, line: {tension: 0.4, borderWidth: 2, }, }, layout: {padding: {top: 3, bottom: 3}}},
        }
    },

    mounted() {
        this.getActivity();
    },

    methods: {
        getActivity() {
            this.$factory.analytics.get60DaysActivity()
                .then((response) => {
                    console.debug('Past60DaysActivity',response);
                    this.graphInfo = response.graph;
                })
        },
    },
}
</script>

<style scoped>

</style>
